export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.initializeMenu()
        NavbarComponent.langsSwitcher()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static initializeMenu() {
        $('.menu__closenav').on('click', (e) => {
            $('body').removeClass('menu-open')
            $('.menu__item a').removeClass('current')
            $('.menu__item').removeClass('submenu__show')
        })

        if (window.matchMedia('(min-width: 1000px)').matches) {
            $('.menu__item__link').on('click', function (e) {
                if (!$(this).hasClass('current')) {
                    e.preventDefault()

                    $('.menu__item a').removeClass('current')
                    $('.menu__item').removeClass('submenu__show')

                    if ($(this).hasClass('menu__item__link__sub')) {
                        $(this).addClass('current')
                        $('body').addClass('menu-open')
                        $(this).parent('.menu__item').addClass('submenu__show')
                    } else {
                        $('body').removeClass('menu-open')
                        $(this).removeClass('current')
                        $(this).parent('.menu__item').removeClass('submenu__show')
                        window.location.href = $(this).attr('href')
                    }
                } else {
                    $('body').removeClass('menu-open')
                    $(this).removeClass('current')
                    $(this).parent('.menu__item').removeClass('submenu__show')
                    window.location.href = $(this).attr('href')
                }
            })
        }
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            body.toggleClass('menu-open')

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if (body.hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('classic')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    }
                }
            }
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }

    static langsSwitcher() {
        const current_lang = $('.tools__item__lang__current')
        const list_langs = $('.tools__item__lang__langs')

        // Open submenu
        $(current_lang).on('click', () => {
            $(list_langs).toggleClass('tools__item__lang__langs--open')
        })

        // Select lang
        $(list_langs).on('click', () => {
            $(list_langs).toggleClass('ttools__item__lang__langs--open')

            setTimeout(() => {
                const currentLangCode = $('html').attr('lang')
                $('.tools__item__lang__current span').html(currentLangCode.substr(0, 2))
            }, '1000')
        })

        // Fix current lang
        setTimeout(() => {
            const currentLangCode = $('html').attr('lang')
            $('.tools__item__lang__current span').html(currentLangCode.substr(0, 2))
        }, '1000')
    }
}
